<template>
    <div>
        <b-overlay :show="loading">
            <b-card>
                <b-form @submit.prevent>
                    <app-collapse>
                        <app-collapse-item title="Búsqueda">
                            <b-row>
                                <b-col md="6">
                                    <b-form-group
                                    >
                                        <h5 class="font-weight-bold">
                                            Otec
                                        </h5>
                                        <div class="form-label-group">
                                            <v-select
                                                    v-model="otecSelected"
                                                    placeholder="Seleccione Otec Holding"
                                                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                                    label="name"
                                                    :options="otecHolding"
                                                    @input="selectOtec"
                                            />
                                        </div>
                                    </b-form-group>
                                </b-col>
                                <b-col md="6">
                                    <b-form-group
                                    >
                                        <h5 class="font-weight-bold">
                                            Holding
                                        </h5>
                                        <div class="form-label-group">
                                            <autocomplete-infinity
                                                    url="entidades/holding"
                                                    placeholder="Seleccione Holding"
                                                    @item-selected="selectHolding"
                                                    label="nombre_holding"
                                                    id="holding"
                                                    itemsPerPage="15"
                                                    :params="getFiltersHoldings()"
                                            ></autocomplete-infinity>
                                        </div>
                                    </b-form-group>
                                </b-col>
                                <b-col md="6">
                                    <b-form-group
                                    >
                                        <h5 class="font-weight-bold">
                                            Empresa
                                        </h5>
                                        <div class="form-label-group">
                                            <autocomplete-infinity
                                                    url="entidades/empresa"
                                                    placeholder="Seleccione Cliente"
                                                    @item-selected="selectEmpresa"
                                                    label="razon_social_empresa"
                                                    id="empresa"
                                                    itemsPerPage="15"
                                                    :params="getFiltersEmpresas()"
                                            ></autocomplete-infinity>
                                        </div>
                                    </b-form-group>
                                </b-col>
                                <b-col md="6">
                                    <b-form-group
                                    >
                                        <h5 class="font-weight-bold">
                                            Cursos
                                        </h5>
                                        <div class="form-label-group">
                                            <b-form-group>
                                                <autocomplete-infinity
                                                        url="entidades/platform-version-course"
                                                        placeholder="Seleccione Curso"
                                                        @item-selected="selectCurso"
                                                        label="description"
                                                        itemsPerPage="15"
                                                        id="curso"
                                                        :params="getFiltersCourses()"
                                                ></autocomplete-infinity>

                                            </b-form-group>
                                        </div>
                                    </b-form-group>
                                </b-col>

                                <b-col md="12">
                                    <div class="text-right mt-2">
                                        <b-button
                                                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                                                variant="outline-primary"
                                                type="submit"
                                                @click="getList">

                                            <feather-icon
                                                    icon="SearchIcon"
                                                    class="mr-50"
                                            />
                                            <span class="align-middle">Buscar</span>
                                        </b-button>
                                        <b-button class="d-none"
                                                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                                                  variant="outline-primary"
                                                  type="submit">

                                            <feather-icon
                                                    icon="RefreshCcwIcon"
                                                    class="mr-50"
                                            />
                                            <span class="align-middle">Limpiar</span>
                                        </b-button>
                                    </div>
                                </b-col>
                            </b-row>
                        </app-collapse-item>
                    </app-collapse>
                </b-form>
            </b-card>
            <b-card>
                <h4 class="card-title">Listado de certificados personalizados</h4>
                <div>
                    <b-row class="pl-1" >
                        <div class="d-none">{{notificaciones}}</div>
                        <b-col cols="12" class="text-right mr-2">
                            <b-button
                                      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                                      variant="outline-primary"
                                      type="submit"
                                      @click="nuevo">

                                <feather-icon
                                        icon="FilePlusIcon"
                                        class="mr-50"
                                />
                                <span class="align-middle">Nuevo</span>
                            </b-button>
                        </b-col>
                        <b-col cols="3" class="mt-2">
                            <div class="d-flex align-items-center mb-1 mb-md-0">
                                <label>Mostrar</label>
                                <b-form-select
                                        @change="getList"
                                        id="perPageSelect"
                                        v-model="perPage"
                                        :options="pageOptions"
                                        size="sm"
                                        class="mx-50 col-md-3"
                                ></b-form-select>
                                <label>filas por página</label>
                            </div>
                        </b-col>

                        <b-col cols="5" >
                            <b-pagination
                                    @change="onPageChange"
                                    v-model="page"
                                    :total-rows="totalRows"
                                    :per-page="perPage"
                                    align="center"
                                    class="mt-2 mr-1"
                                    aria-controls="my-table"
                            />
                        </b-col>
                        <b-col  cols="4" class="mt-2 pl-1">
                            <div class="d-flex justify-content-end pr-1">
                                <span class="text-muted" >  Mostrando del {{from}} al {{to}} de {{totalRows}} filas</span>
                            </div>
                        </b-col>
                    </b-row>


                    <b-col cols="12">
                        <b-table
                                striped
                                hover
                                small
                                :per-page="perPage"
                                :items="listado"
                                :fields="fields"
                                responsive
                                class="mb-0"
                                id="my-table"
                                ref="table"
                                show-empty
                        >
                            <template #cell()="data">
                                {{ data.value ? data.value : '-' }}
                            </template>
                            <template #cell(state)="data">
                                <b-badge :variant="data.item.state == 'active' ? 'light-success': 'light-secondary'">
                                    {{ data.item.state == 'active' ? 'Activo': 'Inactivo' }}
                                </b-badge>
                            </template>
                            <template #cell(accion)="data">
                                <!-- Column: Action -->
                                <div class="text-nowrap">
                                    <feather-icon
                                            v-b-tooltip.hover.v-primary="'Modificar'"
                                            icon="EditIcon"
                                            class="mr-1"
                                            @click="modificar(data.item)"


                                    />
                                    <!--<feather-icon
                                                  v-b-tooltip.hover.v-primary="'Eliminar'"
                                                  icon="FileMinusIcon"
                                                  class="mr-1"
                                                  variant="flat-primary"
                                                  @click="eliminar"
                                    />-->
                                    <feather-icon v-show="data.item.state == 'active'"
                                                  v-b-tooltip.hover.v-primary="'Inactivar'"
                                                  icon="CheckCircleIcon"
                                                  class="mr-1"
                                                  variant="flat-primary"
                                                  @click="actualizarEstado(data.item)"
                                    />
                                    <feather-icon v-show="data.item.state == 'inactive'"
                                                  v-b-tooltip.hover.v-primary="'Activar'"
                                                  icon="CircleIcon"
                                                  class="mr-1"
                                                  variant="flat-primary"
                                                  @click="actualizarEstado(data.item)"
                                    />
                                    <feather-icon
                                                  v-b-tooltip.hover.v-primary="'Copiar Personalización'"
                                                  icon="CopyIcon"
                                                  class="mr-1"
                                                  variant="flat-primary"
                                                  @click="copiar(data.item)"
                                    />
                                </div>
                            </template>
                            <template #empty="scope">
                                <div class="text-center">No existen resultados</div>
                            </template>
                        </b-table>

                    </b-col>

                </div>

            </b-card>
        </b-overlay>
    </div>
</template>

<script>
    import {
        BTable,
        BProgress,
        BBadge,
        BPagination,
        BRow,
        BCol,
        BFormSelect,
        BTfoot,
        BTr,
        BTh,
        BFormCheckbox,
        BButton,
        BTooltip,
        BListGroupItem,
        BCardText,
        BAvatar,
        BOverlay,
        VBTooltip
    } from 'bootstrap-vue'
    import Ripple from 'vue-ripple-directive'
    import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
    import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
    import vSelect from 'vue-select'
    import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
    import store from "@/store";
    export default {
        name: "Listar",
        components: {
            BTable,
            BProgress,
            BBadge,
            BPagination,
            BRow,
            BCol,
            BFormSelect,
            BTfoot,
            BTr,
            BTh,
            BFormCheckbox,
            BButton,
            BTooltip,
            BListGroupItem,
            BCardText,
            BAvatar,
            AppCollapse,
            AppCollapseItem,
            BOverlay,
            vSelect,
            ToastificationContent,
        },
        directives: {
            'b-tooltip': VBTooltip,
            Ripple,
        },
        data() {
            return {
                fields: [
                    {key: 'id_certificate', label: 'ID', display: false},
                    {key: 'otec', label: 'otec', display: true},
                    {key: 'holding', label: 'holding ', display: true},
                    {key: 'empresa', label: 'Empresa', display: true},
                    {key: 'curso', label: 'Curso', display: true},
                    {key: 'id_curso_moodle', label: 'id Moodle', display: true},
                    {key: 'state', label: 'estado', display: true},
                    {key: 'accion', label: 'acciones', display: true},
                ],
                perPage: 50,
                page: 1,
                pageOptions: [50, 100, 500, 1000],
                filter: {
                    otec: '',
                    curso: '',
                    holding: '',
                    empresa: '',
                },
                otecSelected: null,
            }
        },
        mounted() {
            this.getList()
            this.getOtecHolding()
        },
        methods: {
            getList() {
                let filterData = {
                    'page': this.page,
                    'perPage': this.perPage,
                    'filter':this.filter
                }
                this.$store.dispatch('gestionarCertificados/getList',filterData)
            },
            getOtecHolding() {
                let filterData = {
                    "empresa" :this.filter.empresa,
                    "holding" :this.filter.holding,
                    "curso" :this.filter.curso,
                }
                this.$store.dispatch('gestionarCertificados/getOtecHolding', filterData)
            },
            onPageChange(page) {
                this.page = page;
                this.getList();
            },
            onChangePerPage() {
                this.page = 1;
                this.getList();
            },
            selectHolding(holding) {
                this.filter.holding = holding != null ? holding.id_holding : '';
            },
            selectEmpresa(empresa) {
                this.filter.empresa = empresa != null ? empresa.id_empresa : '';
            },
            selectCurso(curso) {
                this.filter.curso = curso != null ? curso.id_platform_version_course : '';
            },
            selectOtec(otec) {
                this.filter.otec = otec != null ? otec.id_otec_holding : '';
            },

            getFiltersHoldings(){
                return {
                    "empresa" :this.filter.empresa,
                    "otec" :this.filter.otec,
                    "curso" :this.filter.curso,
                }
            },
            getFiltersCourses(){
                return {
                    "empresa" :this.filter.empresa,
                    "otec" :this.filter.otec,
                    "holding" :this.filter.holding,
                }
            },
            getFiltersEmpresas(){
                return {
                    "holding" :this.filter.holding,
                    "otec" :this.filter.otec,
                    "curso" :this.filter.curso,
                }
            },
            nuevo() {
                this.$router.push({ name: "nuevo_certificado" });
            },
            modificar(item){
                this.$router.push({ name: 'editar_certificado', params: { id: item.id_certificate } })
            },
            copiar(item){
                this.$router.push({ name: 'copiar_certificado', params: { id: item.id_certificate } })
            },
            actualizarEstado(item){
                let estadoActualizado = item.state == 'active' ? 'inactive' : 'active'
                let data = {
                    'state': estadoActualizado,
                    'id_certificate': item.id_certificate,
                }
                this.$store.dispatch('gestionarCertificados/actualizarEstado', data)
                this.getList()
            },

            eliminar() {
                this.$bvModal
                    .msgBoxConfirm('Se eliminara la personalización del Certificado!!', {
                        title: '¿Está seguro?',
                        size: 'sm',
                        okVariant: 'primary',
                        okTitle: 'Si, eliminar',
                        cancelTitle: 'No',
                        cancelVariant: 'outline-primary',
                        hideHeaderClose: false,
                        centered: true,
                    })
                    .then(value => {
                        if (value) {
                            //eliminar
                        }
                    })
            },
            showToast(variant, mensaje) {
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Notificación',
                        icon: 'BellIcon',
                        text: mensaje,
                        variant,
                    },
                })
            },
        },
        computed: {
            totalRows() {
                return this.$store.state.gestionarCertificados.rowsTotal;
            },
            to() {
                return this.$store.state.gestionarCertificados.to;
            },
            from() {
                return this.$store.state.gestionarCertificados.from;
            },
            listado() {
                return this.$store.state.gestionarCertificados.list
            },
            loading() {
                return this.$store.state.gestionarCertificados.loading;
            },
            otecHolding() {
                return this.$store.state.gestionarCertificados.otecHolding;
            },
            notificaciones() {
                let state = this.$store.state.gestionarCertificados.state
                let mensaje = ''
                let variant = ''
                if (state == '1') {
                    mensaje = 'El estado se ha actuactizado correctamente'
                    variant = 'success'
                }
                if(mensaje.length>0){
                    this.getList()
                    store.commit('gestionarCertificados/setEstado', 0)
                    this.showToast(variant,mensaje)
                }

            },
        },
    }
</script>

<style scoped>

</style>
